
<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container v-else class="my-2">

			<form @submit.prevent="addShopInvoices" autocomplete="off">
				<v-layout row wrap text-center my-4>

					<v-flex xs12 lg6 xl6 md6 sm6>
						<!-- {{shop_invoice_items_list}} -->
						<h1>New Invoice</h1>
					</v-flex>
					<v-flex xs12 lg6 xl6 md6 sm6 >
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.shop_invoices.add_btn}}
                            <v-icon right dark>mdi-plus</v-icon>
                        </v-btn>
					</v-flex>
                    
				</v-layout>
				<v-layout row wrap>

					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-autocomplete class="mx-1" clearable :items="customers" v-model="shop_invoices.customer_id" dense filled outlined item-text="customer_username" item-value="customer_id" :return-object="false" :label="$store.getters.language.data.customers.customer_username">
						</v-autocomplete>
					</v-flex>
					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-autocomplete class="mx-1" clearable :items="customers" v-model="shop_invoices.customer_id" dense filled outlined item-text="customer_phone" item-value="customer_id" :return-object="false" :label="$store.getters.language.data.customers.customer_phone">
						</v-autocomplete>
					</v-flex>
					<v-flex xs12 lg4 xl4 md5 sm5>
						<v-text-field v-model="shop_invoices.shop_invoice_note" type="text" :label="$store.getters.language.data.shop_invoices.shop_invoice_note" dense class="mx-1" filled outlined>
						</v-text-field>
					</v-flex>
					<v-flex xs12 lg3 xl3 md4 sm5>
						<v-select class="mx-1" clearable :items="['pending','reserved','delivered','direct']" v-model="shop_invoices.shop_invoice_status" dense filled outlined item-text="shop_invoice_status" item-value="shop_invoice_status" :return-object="false" :label="$store.getters.language.data.shop_invoices.shop_invoice_status" disabled>
						</v-select>
					</v-flex>

					<!-- <v-flex xs12 lg3 xl3 md4 sm5>
                        <v-text-field v-model="shop_invoices.shop_invoice_date" type="datetime-local" :label="$store.getters.language.data.shop_invoices.shop_invoice_date" dense
                            class="mx-1"  filled outlined  required>
                        </v-text-field>
                    </v-flex> -->

					<v-flex xs12 lg3 xl3 md4 sm5>
						<v-select class="mx-1" clearable :items="users" v-model="shop_invoices.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex>

				</v-layout>
			</form>
			<form @submit.prevent="addShopInvoiceItemList" autocomplete="off">
				<v-layout row wrap>

					<!-- <v-flex xs12 lg3 xl3 md4 sm5>
							<v-select class="mx-1" clearable :items="shop_invoices" v-model="shop_invoice_items.shop_invoice_id" dense filled outlined item-text="shop_invoice_id" item-value="shop_invoice_id" :return-object="false" :label="$store.getters.language.data.shop_invoices.shop_invoice_id">
							</v-select>
						</v-flex> -->

					<v-flex xs12 lg3 xl3 md4 sm5>
						<v-autocomplete class="mx-1" clearable :items="shop_items" v-model="shop_invoice_items.shop_item_id" dense filled outlined item-text="shop_item_id" item-value="shop_item_id" :label="$store.getters.language.data.shop_items.shop_item_id" @change="updatePrice">
						</v-autocomplete>
					</v-flex>
					<!-- {{shop_invoice_items.shop_invoice_item_price}} -->
					<v-flex xs12 lg2 xl2 md3 sm5>
						<v-text-field v-model="shop_invoice_items.shop_invoice_item_price" type="number" :label="$store.getters.language.data.shop_invoice_items.shop_invoice_item_price" dense class="mx-1" filled outlined disabled>
						</v-text-field>
					</v-flex>
					<!-- quantity -->
					<v-flex xs12 lg3 xl3 md4 sm5>
						<v-text-field v-model="shop_invoice_items.shop_invoice_item_quantity" type="number" :label="$store.getters.language.data.shop_invoice_items.shop_invoice_item_quantity" dense class="mx-1" filled outlined required>
						</v-text-field>
					</v-flex>
					<!-- <v-flex xs12 lg3 xl3 md4 sm5>
						<v-select class="mx-1" clearable :items="shop_items" v-model="shop_invoice_items.shop_invoice_item_price" dense filled outlined item-text="shop_item_price" item-value="shop_item_id" :label="$store.getters.language.data.shop_items.shop_item_price">
						</v-select>
					</v-flex> -->

					<!-- <v-flex xs12 lg3 xl3 md4 sm5>
						<v-select class="mx-1" clearable :items="['pending','complete','returned']" v-model="shop_invoice_items.shop_invoice_item_status" dense filled outlined item-text="shop_invoice_item_status" item-value="shop_invoice_item_status" :return-object="false" :label="$store.getters.language.data.shop_invoice_items.shop_invoice_item_status" disabled>
						</v-select>
					</v-flex> -->

					<!-- <v-flex xs12 lg3 xl3 md4 sm5>
						<v-select class="mx-1" clearable :items="users" v-model="shop_invoice_items.user_id" dense filled outlined item-text="user_username" item-value="user_id" :return-object="false" :label="$store.getters.language.data.users.user_username" disabled>
						</v-select>
					</v-flex> -->

					<!-- <v-flex xs12 lg2 xl2 md2 sm4>
							<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.shop_invoice_items.add_btn}}</v-btn>
						</v-flex> -->
					<v-flex xs12 lg2 xl2 md2 sm4>
						<v-btn color="primary" :loading="loading_btn" type="submit">{{$store.getters.language.data.shop_invoice_items.add_btn}}</v-btn>
					</v-flex>
				</v-layout>
			</form>
			<v-layout row wrap mt-5>
				<v-flex xs12 lg3 xl3 md4 sm5>
					<v-text-field v-model="shop_invoices.shop_invoice_price" type="text" :label="$store.getters.language.data.shop_invoices.shop_invoice_price" dense class="mx-1" filled outlined required disabled>
					</v-text-field>
				</v-flex>

				<v-flex xs12 lg3 xl3 md4 sm5>
					<v-text-field v-model="shop_invoices.shop_invoice_discount" type="text" :label="$store.getters.language.data.shop_invoices.shop_invoice_discount" dense class="mx-1" filled outlined required>
					</v-text-field>
				</v-flex>

				<v-flex xs12 lg3 xl3 md4 sm5>
					<v-text-field v-model="shop_invoices.shop_invoice_final_price" type="text" :label="$store.getters.language.data.shop_invoices.shop_invoice_final_price" dense class="mx-1" filled outlined required disabled>
					</v-text-field>
				</v-flex>
			</v-layout>
			<!-- show the invoice_item_list -->
			<v-layout row wrap mt-5>
				<v-flex xs12>

					<table class="report-table" style="width:100%" border="1">
						<thead>
							<tr>
								<th>Barcode</th>
								<th>Quantity</th>
								<th>Price</th>
								<th>Status</th>

							</tr>
						</thead>
						<tbody>
							<tr v-for="item in shop_invoice_items_list" :key="item.shop_invoice_item_id">
								<td>{{item.shop_item_id}}</td>
								<td>{{item.shop_invoice_item_quantity}}</td>
								<td>{{item.shop_invoice_item_price}}</td>
								<td>{{item.shop_invoice_item_status}}</td>
							</tr>
						</tbody>
					</table>

				</v-flex>
			</v-layout>

			<!-- <v-layout row wrap mt-5>
				<v-flex xs12>
					<v-card>
						<v-card-text>
							<v-data-table :headers="headers" show-select v-model="selected_rows" :search="search" :items="rows" class="elevation-0" item-key="shop_invoice_id">
								<template v-slot:[`item.shop_invoice_id`]="{ item }">
									<div>
										<v-btn icon :to="'/shop_invoices-list/'+item.shop_invoice_id" color="teal" class="mx-1">
											<v-icon> mdi-pencil-outline </v-icon>
										</v-btn>
										<v-btn color="error" icon class="mx-1" @click="selectShopInvoices(item)">
											<v-icon> mdi-delete-outline </v-icon>
										</v-btn>
									</div>
								</template>
							</v-data-table>
						</v-card-text>

						<v-card-actions v-if="selected_rows.length > 0">
							<v-btn color="error" @click="deleteShopInvoicesList">{{$store.getters.language.data.shop_invoices.delete_list_btn}}</v-btn>
						</v-card-actions>

					</v-card>
				</v-flex>
			</v-layout> -->
		</v-container>
		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.shop_invoices.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.shop_invoices.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteShopInvoices(selected_shop_invoices.shop_invoice_id)">
						{{$store.getters.language.data.shop_invoices.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>
<script>
	import requests from './../../requests/shop_invoices.request.js'
	import itemRequests from './../../requests/shop_invoice_items.request.js'
	export default {
		data() {
			return {
				shop_invoices: {
					shop_invoice_price: 0,
					shop_invoice_discount: 0,
					shop_invoice_final_price: 0,
					shop_invoice_status: 'pending',
				},
				shop_invoice_items: {
					shop_item_id: null,
					shop_invoice_item_quantity: null,
					shop_invoice_item_price: null,
					shop_invoice_item_status: 'pending',
				},
				shop_invoice_items_list: [],
				search: '',
				loading: true,
				loading_btn: false,
				selected_rows: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				rows: [],
				selected_shop_invoices: {},
				delete_dialog: false,
				headers: [


					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_price,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_price',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_discount,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_discount',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_final_price,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_final_price',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_status,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_status',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_note,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_note',
					},
					{
						text: this.$store.getters.language.data.shop_invoices.shop_invoice_date,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_date',
					},
					{
						text: this.$store.getters.language.data.customers.customer_id,
						align: 'start',
						sortable: true,
						value: 'customer_id',
					},
					{
						text: this.$store.getters.language.data.users.user_id,
						align: 'start',
						sortable: true,
						value: 'user_id',
					}, {
						text: this.$store.getters.language.data.actions,
						align: 'start',
						sortable: true,
						value: 'shop_invoice_id',
					}
				],
			}
		},
		computed: {
			customers() {
				return this.$store.getters.customers_list
			},
			users() {
				return this.$store.getters.users_list
			},
			shop_items() {
				return this.$store.getters.shop_items_list
			},
			user() {
				return this.$store.getters.user
			},
		},
		mounted() {
			this.readShopInvoices();
			this.shop_invoices.user_id = this.user.user_id
			this.shop_invoice_items.user_id = this.user.user_id
			this.shop_invoice_items.shop_invoice_item_quantity = 1
			this.shop_invoices.shop_invoice_price = 0
			this.shop_invoices.shop_invoice_discount = 0
			this.shop_invoices.shop_invoice_final_price = 0

		},
		watch: {
			'shop_invoices.shop_invoice_price': function (val) {
				this.updateFinalPrice();
			},
			'shop_invoices.shop_invoice_discount': function (val) {
				this.updateFinalPrice();
			},
		},
		methods: {
			updateFinalPrice() {
				// Calculate the final price here based on shop_invoice_price and shop_invoice_discount
				this.shop_invoices.shop_invoice_final_price = this.shop_invoices.shop_invoice_price - this.shop_invoices.shop_invoice_discount;
			},
			updatePrice() {
				// Find the selected shop_item_id in the shop_items array
				const selectedItem = this.shop_items.find(
					(item) => item.shop_item_id === this.shop_invoice_items.shop_item_id
				);

				// If a matching item is found, update shop_invoice_item_price
				if (selectedItem) {
					console.log(selectedItem)
					console.log(selectedItem.shop_item_price)
					this.shop_invoice_items.shop_invoice_item_price = selectedItem.shop_item_price;
					console.log(this.shop_invoice_items.shop_invoice_item_price)
				} else {
					console.log("selectedItem not found")
					// Handle the case when no matching item is found
					this.shop_invoice_items.shop_invoice_item_price = null;
				}
			},
			addShopInvoiceItemList() {
				if (this.shop_invoice_items.shop_item_id == null) {
					this.snackbar.value = true
					this.snackbar.text = 'choose an item'
					this.snackbar.color = 'error'
					return
				}
				let item = this.shop_invoice_items
				this.shop_invoice_items_list.push({
					shop_item_id: item.shop_item_id,
					shop_invoice_item_quantity: item.shop_invoice_item_quantity,
					// shop_invoice_item_price: item.shop_item_price,
					// shop_invoice_item_discount: item.shop_item_discount,
					// shop_invoice_item_final_price: item.shop_item_final_price,
					shop_invoice_item_status: item.shop_invoice_item_status,
					shop_invoice_item_price: item.shop_invoice_item_price,

					// shop_invoice_item_note: item.shop_item_note,
					// shop_invoice_item_date: item.shop_item_date,
					user_id: item.user_id,

				})

				this.shop_invoices.shop_invoice_price += item.shop_invoice_item_price * item.shop_invoice_item_quantity
				this.shop_invoice_items = {
					user_id: this.user.user_id,
					shop_invoice_item_quantity: 1,
					shop_invoice_item_status: 'pending',
				}
			},
			// addShopInvoiceItems() {
			// 	this.loading_btn = true
			// 	itemRequests.createShopInvoiceItems(this.shop_invoice_items).then(r => {
			// 		if (r.status == 200) {
			// 			this.shop_invoice_items = {
			// 				user_id: this.user.user_id
			// 			}
			// 			this.rows.push(
			// 				r.data.new_data
			// 			)
			// 			this.snackbar = {
			// 				value: true,
			// 				text: 'ShopInvoiceItems Added',
			// 				color: 'success'
			// 			}
			// 		} else {
			// 			this.snackbar = {
			// 				value: true,
			// 				text: 'Fail to add ShopInvoiceItems',
			// 				color: 'error'
			// 			}
			// 		}
			// 	})
			// 		.finally(() => {
			// 			this.loading_btn = false
			// 		})

			// },
			addShopInvoiceItems() {
				this.loading_btn = true

				itemRequests.createShopInvoiceItemsList({
					list: this.shop_invoice_items_list
				}).then(r => {
					if (r.status == 200) {
						this.shop_invoice_items = {
							user_id: this.user.user_id,
							shop_invoice_item_quantity: 1,
							shop_invoice_item_status: 'pending',

						}
						this.shop_invoice_items_list = []
						this.rows.push(
							r.data.new_data
						)
						this.snackbar = {
							value: true,
							text: 'ShopInvoiceItems Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ShopInvoiceItems',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			addShopInvoices() {
                if (this.shop_invoices.shop_invoice_price == 0) {
                    this.snackbar = {
                        value: true,
                        text: 'Shop Invoice Items is empty',
                        color: 'error'
                    }
                    return
                }
				this.loading_btn = true
				requests.createShopInvoices(this.shop_invoices).then(r => {
					if (r.status == 200) {
						this.shop_invoices = {
							user_id: this.user.user_id,
							shop_invoice_price: 0,
							shop_invoice_discount: 0,
							shop_invoice_final_price: 0,
							shop_invoice_status: 'pending',

						}
						this.rows.push(
							r.data.new_data
						)
						// for each shop_invoice_item_list add shop_invoice_id
						this.shop_invoice_items_list.forEach(element => {
							element.shop_invoice_id = r.data.new_data.shop_invoice_id
						});
						this.addShopInvoiceItems()
						this.snackbar = {
							value: true,
							text: 'ShopInvoices Added',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to add ShopInvoices',
							color: 'error'
						}
					}
				})
					.finally(() => {
						this.loading_btn = false
					})

			},
			deleteShopInvoices(shop_invoice_id) {
				requests.deleteShopInvoices(shop_invoice_id).then(r => {
					this.delete_dialog = false
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return f.shop_invoice_id != shop_invoice_id
						})
						this.snackbar = {
							value: true,
							text: 'ShopInvoices Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			deleteShopInvoicesList() {
				let ids = this.selected_rows.map(m => m.shop_invoice_id)
				requests.deleteShopInvoicesList(ids).then(r => {
					if (r.status == 200) {
						this.rows = this.rows.filter(f => {
							return ids.indexOf(f.shop_invoice_id) == -1
						})
						this.snackbar = {
							value: true,
							text: this.selected_rows.length + ' ShopInvoices Deleted',
							color: 'success'
						}
					} else {
						this.snackbar = {
							value: true,
							text: 'Delete Faild',
							color: 'error'
						}
					}
				})
			},

			readShopInvoices() {
				this.loading = true
				requests.getAllShopInvoices().then(r => {
					if (r.status == 200) {
						this.rows = r.data.rows
						this.loading = false
					} else {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopInvoices',
							color: 'error'
						}
					}
				})
					.catch(e => {
						this.snackbar = {
							value: true,
							text: 'Fail to read ShopInvoices',
							color: 'error'
						}
					})
					.finally(() => {
						this.loading = false
					})
			},
			selectShopInvoices(i) {
				this.selected_shop_invoices = i
				this.delete_dialog = true
			},
		},
	}
</script>
                    